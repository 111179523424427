import { Box, Button, Grid, Typography } from "@mui/material";
import jwtDecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ModalBox from "src/components/ModalBox";
import useAuth from "src/hooks/useAuth";
import { useDispatch } from "src/store";
import { updateApiMode } from "src/store/actions/apiModeAction";
import { auth_token_expiry_warning } from "src/utils/globalStaticData";
import { setDefaultCurrency } from "src/utils/helper";
import { getNewAccessToken } from "src/utils/NetworkUtils";
import JWTLogin from "../Login/LoginJWT";
import LoginOTP from "../Login/LoginOTP";
import supportWidget from "src/utils/SupportWidget";

const idle_events = ["mousedown", "keydown"];

const AccessTokenExp = () => {
  const [open, setOpen] = useState(false);
  const [show_login, setShowLogin] = useState(false);
  const [access_token, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );

  const { logout, otp_login_user, user, initialise } = useAuth();
  const { mode } = useSelector((state) => state.mode);
  const dispatch = useDispatch();
  const [default_state, setDefaultState] = useState(mode);
  const access_exp_ref = useRef();
  const timerRef = useRef();
  const [timer, setTimer] = useState(30);

  const getTokenExpiry = (access_token) => {
    if (access_token) {
      const { exp } = jwtDecode(access_token);
      return exp * 1000;
    }
  };

  useEffect(() => {
    window.addEventListener("storage", () => {
      setAccessToken(localStorage.getItem("access_token"));
      const currentMode = localStorage.getItem("apiMode") || "test";
      setDefaultState(currentMode);
    });

    return () => {
      window.removeEventListener("storage", () => {});
    };
  }, []);

  useEffect(() => {
    if (default_state !== mode) {
      (async () => {
        dispatch(updateApiMode(default_state));
        await initialise();
      })();
    }
  }, [default_state]);

  useEffect(() => {
    idle_events.forEach((item) => window.removeEventListener(item, () => {}));

    idle_events.forEach((item) => {
      let event_fired = false;
      window.addEventListener(item, () => {
        const access_token = localStorage.getItem("access_token");
        const access_token_expires_at = getTokenExpiry(access_token);
        //Clear access_exp_ref is user is active (event fired) within 15 min before token expiry
        if (
          access_token_expires_at &&
          access_token_expires_at - Date.now() <= auth_token_expiry_warning &&
          access_token_expires_at - Date.now() > 30000
        ) {
          if (!event_fired) {
            event_fired = true; //Store last_active_at only once
            localStorage.setItem("last_active_at", new Date().toISOString());
            clearTimeout(access_exp_ref.current);
            access_exp_ref.current = null;
            setOpen(false);
          }
        }
      });
    });

    return () => {
      idle_events.forEach((item) => window.removeEventListener(item, () => {}));
    };
  }, [access_token]);

  useEffect(() => {
    if (access_exp_ref.current) {
      clearTimeout(access_exp_ref.current);
      access_exp_ref.current = null;
    }
    setShowLogin(false);

    if (access_token) {
      const current_date = Date.now();
      const access_token_expires_at = getTokenExpiry(access_token);
      if (access_token_expires_at - current_date >= 30000) {
        access_exp_ref.current = setTimeout(() => {
          const access_token = localStorage.getItem("access_token");
          const last_active_at = localStorage.getItem("last_active_at");

          //Checking if user was active within the previous 14.5 min
          if (
            access_token &&
            new Date() - new Date(last_active_at) >=
              auth_token_expiry_warning - 30000
          ) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }, access_token_expires_at - current_date - 30000);
      }
    }

    return () => {
      setOpen(false);
    };
  }, [access_token]);

  useEffect(() => {
    if (!!open) {
      timerRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      timerRef.current = null;
      setTimer(30);
    }
    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  }, [open]);

  useEffect(() => {
    if (timer < 1) {
      expireSession();
    }
  }, [timer]);

  const expireSession = () => {
    localStorage.clear();
    localStorage.setItem("apiMode", mode);
    setDefaultCurrency();
    setShowLogin(true);
    supportWidget.hide();
    setOpen(false);
  };

  const getNewToken = async () => {
    const refresh_token_value = window.localStorage.getItem("refresh_token");
    if (refresh_token_value) {
      await getNewAccessToken(refresh_token_value);
    } else {
      expireSession();
    }
    setOpen(false);
  };

  return (
    <>
      <ModalBox
        open={show_login}
        cancellable={false}
        backgroundStyle={{
          style: { backgroundColor: "#000000DE" },
        }}
        style={{ padding: -4, minWidth: "30%", outline: 0 }}
      >
        {otp_login_user?.is_otp_required ? (
          <LoginOTP
            style={{ boxShadow: "none", margin: -4 }}
            sessionExpiryLogin={true}
          />
        ) : (
          <Box m={5}>
            <Typography variant="h3" sx={{ mb: 1 }}>
              Your session has expired. Please login again to continue.
            </Typography>
            <JWTLogin sessionExpiryLogin={true} user={user} />
          </Box>
        )}
      </ModalBox>
      <ModalBox open={open} cancellable={false}>
        <Grid container textAlign={"center"} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Your session is about to expire in {timer} seconds.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            Do you wish to continue the session?
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" fullWidth onClick={getNewToken}>
              Yes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                logout();
                setOpen(false);
              }}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </ModalBox>
    </>
  );
};

export default AccessTokenExp;
