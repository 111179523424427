import { Box, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import RestrictedAlertBox from "src/components/RestrictedAlertBox";
const { useState } = require("react");

const RouteSearchBox = ({
  user,
  kyb_status,
  suggested_route_list,
  all_route_list,
}) => {
  const [showSuggested, setShowSuggested] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState(false);
  const { mode } = useSelector((state) => state.mode);
  const [openToolTip, setOpenToolTip] = useState(false);

  const DropDownList = ({ props, option, inputValue }) => {
    const matchLabel = match(option.label, inputValue, {
      insideWords: true,
      requireMatchAll: false,
    });
    const partsLabel = parse(option.label, matchLabel);
    return (
      <Box
        {...props}
        disabled={true}
        component={RouterLink}
        to={option.label === "API Reference (Docs)" ? "" : option.link}
        sx={{
          color: "#111633",
          textDecoration: "none",
        }}
        onClick={() => {
          if (option.label === "API Reference (Docs)")
            window.open(option.link, "_blank");
          setSearchValue("");
          setOpen(false);
          setMenu(false);
        }}
      >
        <Typography>
          {partsLabel.map((part, index) => {
            return (
              <Box
                key={index}
                style={{
                  display: "inline",
                  fontWeight: part.highlight ? 700 : 400,
                  background: part.highlight && "#111633",
                  borderRadius: part.highlight && "2px",
                  color: part.highlight && "#fff",
                  padding: part.highlight && "0px 4px",
                }}
              >
                {part.text}
              </Box>
            );
          })}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      onMouseEnter={() => {
        if (
          mode === "live" &&
          (kyb_status !== "APPROVED" ||
            !user.is_mobile_verified ||
            !user.is_email_verified)
        )
          setOpenToolTip(true);
      }}
      onMouseLeave={() => setOpenToolTip(false)}
    >
      {!menu ? (
        <Box>
          <Tooltip
            enterDelay={1}
            slotProps={{
              tooltip: {
                style: {
                  padding: "0",
                },
              },
            }}
            title={<RestrictedAlertBox user={user} />}
            followCursor
            placement="right-start"
            open={openToolTip}
          >
            <Box
              onClick={() => {
                if (
                  mode === "test" ||
                  (user.is_email_verified &&
                    user.is_mobile_verified &&
                    kyb_status === "APPROVED")
                )
                  setMenu(true);
              }}
            >
              <TextField
                disabled={
                  mode === "live" &&
                  (!user.is_mobile_verified ||
                    !user.is_email_verified ||
                    kyb_status !== "APPROVED")
                }
                value={""}
                size="small"
                placeholder="Search..."
                sx={{
                  width: "165px",
                  cursor: "alias",
                  marginRight: "10px",
                  padding: "20px 10px",
                }}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Autocomplete
          disablePortal
          autoComplete
          fullWidth
          open={open || menu}
          onOpen={() => setOpen(true)}
          onClose={() => setMenu(false)}
          noOptionsText={"No result found"}
          options={showSuggested ? suggested_route_list : all_route_list}
          sx={{ width: "350px", marginRight: "10px" }}
          groupBy={() => (showSuggested ? "Suggested Features" : "")}
          onInputChange={(e) => {
            setSearchValue(e.target.value);
            if (e.target.value) setShowSuggested(false);
            else setShowSuggested(true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              fullWidth
              autoFocus
              size="small"
              value={searchValue}
              sx={{ padding: "none" }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => (
            <DropDownList
              props={props}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      )}
    </Box>
  );
};

export default RouteSearchBox;
