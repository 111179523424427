import {
  FETCH_SUMMARY,
  SET_SUMMARY,
  FAILED_SUMMARY_FETCH
} from '../actions/accountSummaryActions';

const initalState = {
  fetching: false,
  accountSummary: {},
  error: null
};

export const accountSummaryReducer = (
  state = initalState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_SUMMARY:
      return { fetching: true, accountSummary: [], error: null };
    case SET_SUMMARY:
      return {
        ...state,
        fetching: false,
        accountSummary: payload,
        error: null
      };
    case FAILED_SUMMARY_FETCH:
      return { ...state, fetching: false, accountSummary: [], error: payload };

    default:
      return state;
  }
};
