import {
  FETCH_CHECKOUT,
  SET_CHECKOUT,
  FAILED_CHECKOUT_FETCH,
  NEW_CHECKOUT_REQUEST,
  NEW_CHECKOUT_SUCCESS,
  NEW_CHECKOUT_FAIL,
  CHECKOUT_RESET
} from '../actions/checkoutAction';

const initalState = {
  fetching: false,
  checkouts: {},
  success: false,
  error: null
};

export const checkoutReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_CHECKOUT:
      return { fetching: true, checkouts: {}, error: null };
    case SET_CHECKOUT:
      return { ...state, fetching: false, checkouts: payload, error: null };
    case FAILED_CHECKOUT_FETCH:
      return { ...state, fetching: false, checkouts: {}, error: payload };

    default:
      return state;
  }
};

export const newCheckoutSessionReducer = (state = {}, { type, payload })=>{
  switch(type){
      case NEW_CHECKOUT_REQUEST:
          return {fetching: true};
      case NEW_CHECKOUT_SUCCESS:
          return {fetching: false, checkouts: payload, success: true};
      case NEW_CHECKOUT_FAIL:
          return {fetching: false, error: payload};
      case CHECKOUT_RESET:
          return {};

      default:
        return state;
  }
};
