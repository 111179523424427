import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import { AuthProvider } from "./contexts/JWTAuthContext";

import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";
import RouterComp from "./RouterComp";
import AccessTokenExp from "./content/pages/Auth/AccessTokenExp/AccessTokenExp";

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.initialize("UA-194169543-1");
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

// User has switched back to the tab
// const onFocus = () => {
//   console.log(document.hasFocus());
//   console.log('Tab is in focus');
// };

// User has switched away from the tab (AKA tab is hidden)
// const onBlur = () => {
//   console.log('Tab is blurred');
// };

// const onMouse = () => {
//   console.log('Mouse is blurred');
// };

// function leaveHandler() {
//   // Process the pointerleave event
//   console.log('leave');
//   // setTimeout(() => {
//   //   console.log('initiating timeout sequence');
//   //   alert('lets go');
//   // }, 5000);
// }

// const enterHandler = () => {
//   set
// };

function App() {
  //// const [focus, setFocus] = useState(true);

  // function leaveHandler() {
  //   setFocus(!focus);
  // let timer = setTimeout(() => {
  //   console.log('initiating timeout sequence...');
  // }, 5000);
  //   console.log('focus', focus);
  //   // focus ? clearTimeout(timer) : null;
  //   console.log('leave');
  // }

  // const enterHandler = () => {
  //   setFocus(focus);
  //   // clearTimeout(fun);
  //   console.log('focus', focus);
  //   console.log('enter');
  // };

  // document.onpointerenter = enterHandler;
  // document.onpointerleave = leaveHandler;

  //// const timer = setTimeout(() => {
  ////   console.log('initiating timeout sequence...');
  //// }, 5000);

  ////useEffect(() => {
  // document.onpointerenter = enterHandler;
  // document.onpointerleave = leaveHandler;
  // console.log('11', focus);
  ////  document.addEventListener('pointerenter', () => {
  ////    setFocus(!focus);
  ////    console.log('enter', focus);
  ////    clearTimeout(timer);
  ////  });

  ////  document.addEventListener('pointerleave', () => {
  ////    setFocus(focus);
  ////    timer;
  // let timer = setTimeout(() => {
  //   console.log('initiating timeout sequence...');
  // }, 5000);
  // focus ? clearTimeout(timer) : null;
  ////    console.log('leave', focus);
  ////  });
  ////}, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Router history={history}>
            <ScrollToTop />
            <CssBaseline />
            <AuthProvider>
              <AccessTokenExp />
              <RouterComp />
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
