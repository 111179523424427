import {
  UPDATE_API_MODE,
  DEVELOPER_UPDATE_API_MODE,
} from "../actions/apiModeAction";

const modeState = {
  mode: localStorage.getItem("apiMode")
    ? localStorage.getItem("apiMode")
    : "test",
};

export const apiModeReducer = (state = modeState, { type, payload }) => {
  switch (type) {
    case UPDATE_API_MODE:
      localStorage.setItem("apiMode", payload.mode);
      return { mode: payload.mode };

    case DEVELOPER_UPDATE_API_MODE:
      return { mode: payload.mode };

    default:
      return state;
  }
};
