import axios from "axios";
import {
  test_api_key,
} from "src/config";
import { apiErrorMessage, reduxErrorMessage } from "src/utils/helper";
import { fetchAllPayments } from "src/utils/NetworkUtils";
export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const SET_PAYMENT = "SET_PAYMENT";
export const FAILED_PAYMENT_FETCH = "FAILED_PAYMENT_FETCH";

export const fetchPayment = () => ({
  type: FETCH_PAYMENT,
});

export const setPayment = (payload) => ({
  type: SET_PAYMENT,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_PAYMENT_FETCH,
  payload,
});

export const getAllPayment = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchPayment());
  try {
    const res = await fetchAllPayments(filterQueryArr, log);
    dispatch(setPayment(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getPaymentById = (id) => async (dispatch) => {
  dispatch(fetchPayment());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };

    // const res = await axios.get(`${BASE_API_URL}/${id}`, config);
    const res = await axios.get(
      `https://api.pay-now.io/api/payment/${id}`,
      config
    );
    const { data, success } = res.data;
    if (success) {
      dispatch(setPayment(data));
    }
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
