import { apiErrorMessage } from "src/utils/helper";
import { fetchAllCustomers } from "src/utils/NetworkUtils";
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const FAILED_CUSTOMER_FETCH = "FAILED_CUSTOMER_FETCH";

export const fetchCustomer = () => ({
  type: FETCH_CUSTOMER,
});

export const setCustomer = (payload) => ({
  type: SET_CUSTOMER,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_CUSTOMER_FETCH,
  payload,
});

export const getAllCustomer = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchCustomer());
  try {
    const res = await fetchAllCustomers(filterQueryArr, log);
    dispatch(setCustomer(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};
