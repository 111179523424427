import { ListSubheader, List, Tooltip } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import { menuItems, partnerMenuItems } from "./items";
import { experimentalStyled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";
import { RBAC_POLICIES } from "src/utils/globalStaticData";
import { useState } from "react";
import RestrictedAlertBox from "src/components/RestrictedAlertBox";

const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding:0;
    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(1, 3)};
      line-height: 1.5;
    }
`
);
const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding:0;
      .MuiListItem-root {
        padding: 1px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};

        .MuiButton-label {
          display: flex;
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(2)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 0;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(12)};
          padding: ${theme.spacing(3)};
          padding-top: ${theme.spacing(1)};
          padding-bottom: ${theme.spacing(1)};
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(18)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }

          &.Mui-active {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;

          .MuiButton-root.Mui-active {
            &:after {
              opacity: 0;
            }
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0, 1, 3.8)};
          }

          .MuiListItem-root {
            padding: 0px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(12)};
              padding: ${theme.spacing(0, 2, 0, 0)};
              font-weight: normal;

              &:before {
                content: '';
                background: ${theme.sidebar.menuItemIconColor};
                opacity: .4;
                transition: ${theme.transitions.create(["background"])};
                width: 2px;
                height: 34px;
                margin-right: ${theme.spacing(2.9)};
              }

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};

                &:before {
                  background: ${theme.sidebar.menuItemIconColorActive};
                  opacity: 1;
                  width: 4px;
                  border-radius: 20px;
                }

                &:after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);
// renderSidebarMenuItems;
function renderSidebarMenuItems({
  items,
  path,
  mode,
  gca_prod_status,
  gca_sandbox_status,
  is_admin,
  is_developer,
  sub_role,
  is_bulk_payout_enabled,
  is_ifn_enabled,
  is_audit_trail_enabled,
  treasury_os,
  tos_invest_account,
  tos_fx_trade,
  tos_org_balance_at_glance,
  is_signature_key_enabled,
  is_plugins_enabled,
  is_sender_validation_enabled,
  is_pricing_visible,
}) {
  return (
    <SubMenuWrapper>
      {items.reduce((ev, item) => {
        if (item.name === "Invoices") {
          if (mode === "live") {
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          } else {
            return ev;
          }
        } else if (item.name === "Sender") {
          if (is_sender_validation_enabled) {
            return reduceChildRoutes({
              ev,
              item,
              path,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              is_sender_validation_enabled,
            });
          } else {
            return ev;
          }
        } else if (item.name === "Bulk Payout") {
          if (is_bulk_payout_enabled) {
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          } else {
            return ev;
          }
        } else if (item.name === "Plugin") {
          if (is_plugins_enabled)
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          else return ev;
        } else if (item.name === "Treasury Dashboard") {
          if (treasury_os?.tos_org_balance_at_glance)
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          else return ev;
        } else if (item.name === "Invest") {
          if (treasury_os?.tos_invest_account)
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              is_bulk_payout_enabled,
              tos_invest_account,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          else return ev;
        } else if (item.name === "Foreign Exchange") {
          if (treasury_os?.tos_fx_trade)
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              is_bulk_payout_enabled,
              tos_fx_trade,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          else return ev;
        } else if (item.name === "Treasury Dashboard") {
          if (
            !treasury_os?.tos_invest_account &&
            !treasury_os?.tos_fx_trade &&
            !treasury_os?.tos_org_balance_at_glance
          )
            return ev;
          else
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              is_bulk_payout_enabled,
              treasury_os,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
        } else if (item.name === "Pricing") {
          if (is_pricing_visible)
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          else return ev;
        } else if (item.name === "Authentication") {
          if (is_signature_key_enabled)
            return reduceChildRoutes({
              ev,
              item,
              path,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              is_signature_key_enabled,
            });
          else return ev;
        } else if (item.name === "Audit Trail") {
          if (is_audit_trail_enabled) {
            return reduceChildRoutes({
              ev,
              item,
              path,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              is_ifn_enabled,
              is_audit_trail_enabled,
            });
          } else {
            return ev;
          }
        } else if (
          item.name === "GCA" ||
          item.name === "Create international payout" ||
          item.name === "Conversions"
        ) {
          if (
            (mode === "test" && gca_sandbox_status !== "PENDING") ||
            (mode === "live" && gca_prod_status !== "PENDING")
          ) {
            return reduceChildRoutes({
              ev,
              path,
              item,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_ifn_enabled,
              is_audit_trail_enabled,
              is_plugins_enabled,
              is_pricing_visible,
            });
          } else {
            return ev;
          }
        } else if (
          item.name === "Users" &&
          !!(is_developer || sub_role?.includes("MAKER"))
        ) {
          return ev;
        } else {
          return reduceChildRoutes({
            ev,
            path,
            item,
            mode,
            gca_prod_status,
            gca_sandbox_status,
            is_admin,
            is_developer,
            sub_role,
            is_bulk_payout_enabled,
            treasury_os,
            tos_invest_account,
            tos_fx_trade,
            tos_org_balance_at_glance,
            is_ifn_enabled,
            is_audit_trail_enabled,
            is_plugins_enabled,
            is_pricing_visible,
            is_signature_key_enabled,
            is_sender_validation_enabled,
          });
        }
      }, [])}
    </SubMenuWrapper>
  );
}

function pathMatchCheck(path, items, valid_path) {
  if (valid_path) {
    return true;
  } else {
    items.forEach((item) => {
      if (!!item.link) {
        if (
          !!matchPath(path, {
            path: item.link,
            exact: false,
          })
        ) {
          valid_path = true;
        }
      } else if (item?.items && item.items.length > 0) {
        valid_path = pathMatchCheck(path, item.items, valid_path);
      }
    });
  }

  return valid_path;
}

function reduceChildRoutes({
  ev,
  path,
  item,
  mode,
  gca_prod_status,
  gca_sandbox_status,
  is_admin,
  is_developer,
  sub_role,
  is_bulk_payout_enabled,
  treasury_os,
  tos_invest_account,
  tos_fx_trade,
  tos_org_balance_at_glance,
  is_ifn_enabled,
  is_audit_trail_enabled,
  is_plugins_enabled,
  is_pricing_visible,
  is_signature_key_enabled,
  is_sender_validation_enabled,
}) {
  const key = item.name;

  if (item.items) {
    const open = pathMatchCheck(path, item.items, false);
    ev.push(
      <SidebarMenuItem
        key={key}
        open={Boolean(open)}
        name={item.name}
        icon={item.icon}
        badge={item.badge}
        isExpanded={item?.isExpanded || false}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
          gca_prod_status,
          gca_sandbox_status,
          mode,
          is_admin,
          is_developer,
          sub_role,
          is_bulk_payout_enabled,
          is_ifn_enabled,
          is_audit_trail_enabled,
          treasury_os,
          tos_invest_account,
          tos_fx_trade,
          tos_org_balance_at_glance,
          is_plugins_enabled,
          is_pricing_visible,
          is_signature_key_enabled,
          is_sender_validation_enabled,
        })}
      </SidebarMenuItem>
    );
  } else {
    if (!is_admin && !is_developer) {
      RBAC_POLICIES["read_only"].includes(item.route_id) &&
        ev.push(
          <SidebarMenuItem
            key={key}
            name={item.name}
            link={item.link}
            badge={item.badge}
            icon={item.icon}
            isExpanded={item?.isExpanded || false}
          />
        );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
          isExpanded={item?.isExpanded || false}
        />
      );
    }
  }

  return ev;
}

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const { mode } = useSelector((state) => state.mode);
  const {
    gca_sandbox_status,
    is_bulk_payout_enabled,
    is_ifn_enabled,
    user,
    kyb_status,
    gca_prod_status,
    is_audit_trail_enabled,
    treasury_os,
    tos_invest_account,
    tos_fx_trade,
    tos_org_balance_at_glance,
    is_plugins_enabled,
    is_pricing_visible,
    is_signature_key_enabled,
    is_sender_validation_enabled,
  } = useAuth();
  const { is_admin, is_developer, sub_role } = user;
  const [openToolTip, setOpenToolTip] = useState(false);
  const { partner_id, partner_level } = user;
  const sidebar_item_list =
    !!partner_id && (partner_level === "L3" || partner_level === "L2")
      ? partnerMenuItems
      : menuItems;

  return (
    <>
      {sidebar_item_list.map((section) => (
        <MenuWrapper
          key={section.heading}
          onMouseEnter={() => {
            if (
              mode === "live" &&
              (kyb_status !== "APPROVED" ||
                !user.is_mobile_verified ||
                !user.is_email_verified)
            )
              setOpenToolTip(true);
          }}
          onMouseLeave={() => setOpenToolTip(false)}
          subheader={
            <ListSubheader disableSticky>{t(section.heading)}</ListSubheader>
          }
        >
          <Tooltip
            enterDelay={1}
            slotProps={{
              tooltip: {
                sx: {
                  p: 0,
                },
              },
            }}
            title={<RestrictedAlertBox user={user} />}
            followCursor
            placement="right-start"
            open={openToolTip}
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname,
              mode,
              gca_prod_status,
              gca_sandbox_status,
              is_admin,
              is_developer,
              sub_role,
              is_bulk_payout_enabled,
              is_ifn_enabled,
              is_audit_trail_enabled,
              treasury_os,
              tos_invest_account,
              tos_fx_trade,
              tos_org_balance_at_glance,
              is_plugins_enabled,
              is_pricing_visible,
              is_signature_key_enabled,
              is_sender_validation_enabled,
            })}
          </Tooltip>
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
