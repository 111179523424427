import {
  FETCH_PAYOUT,
  SET_PAYOUT,
  FAILED_PAYOUT_FETCH,
} from "../actions/payoutAction";

const initialState = {
  fetching: false,
  payouts: {},
  error: null,
};

export const payoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYOUT:
      return { fetching: true, payouts: {}, error: null };
    case SET_PAYOUT:
      return { ...state, fetching: false, payouts: payload, error: null };
    case FAILED_PAYOUT_FETCH:
      return { ...state, fetching: false, payouts: {}, error: payload };
    default:
      return state;
  }
};
