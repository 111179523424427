import axios from "axios";
import {
  test_api_key,
} from "src/config";
import {  reduxErrorMessage } from "src/utils/helper";
import { fetchAllCheckouts } from "src/utils/NetworkUtils";
export const FETCH_CHECKOUT = "FETCH_CHECKOUT";
export const SET_CHECKOUT = "SET_CHECKOUT";
export const FAILED_CHECKOUT_FETCH = "FAILED_CHECKOUT_FETCH";

export const NEW_CHECKOUT_REQUEST = "NEW_CHECKOUT_REQUEST";
export const NEW_CHECKOUT_SUCCESS = "NEW_CHECKOUT_SUCCESS";
export const NEW_CHECKOUT_FAIL = "NEW_CHECKOUT_FAIL";
export const CHECKOUT_RESET = "CHECKOUT_RESET";

export const fetchCheckout = () => ({
  type: FETCH_CHECKOUT,
});

export const setCheckout = (payload) => ({
  type: SET_CHECKOUT,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_CHECKOUT_FETCH,
  payload,
});

export const checkoutReset = () => async (dispatch) => {
  dispatch({ type: CHECKOUT_RESET });
};

export const getAllCheckout = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchCheckout());
  try {
    const res = await fetchAllCheckouts(filterQueryArr, log);
    dispatch(setCheckout(res));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getCheckoutById = (id) => async (dispatch) => {
  dispatch(fetchCheckout());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };

    const res = await axios.get(
      `https://api.pay-now.io/api/checkout/${id}`,
      config
    );
    const { data, success } = res.data;
    if (success) {
      dispatch(setCheckout(data));
    }
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const newCheckoutSession = (
  amount,
  reference,
  currency,
  description,
  expiration,
  redirect_url
) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CHECKOUT_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };
    const { data } = await axios.post(
      "https://api.pay-now.io/api/checkout",
      { amount, reference, currency, description, expiration, redirect_url },
      config
    );
    dispatch({
      type: NEW_CHECKOUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CHECKOUT_FAIL,
      payload: {
        message:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      },
    });
  }
};
