import {
  application_header_regex,
  basic_header_regex,
  text_block_regex,
  text_regex,
  trailer_regex,
  user_header_block_regex,
  user_header_regex,
} from "./regex";

export const getBlocks = (swift) => {
  const basic_header = swift?.match(basic_header_regex)?.groups?.basic_header;
  const application_header = swift?.match(application_header_regex)?.groups
    ?.application_header;
  const user_header = swift?.match(user_header_regex)?.groups?.user_header;
  const text = swift?.match(text_regex)?.groups?.text;
  const trailer = swift?.match(trailer_regex)?.groups?.trailer;

  return { basic_header, application_header, user_header, text, trailer };
};

export const getBasicHeaderBlock = (basic_header) => {
  // let parsed_header = basic_header?.match(basic_header_block_regex);
  return {
    basic_header,
    block_identifier: 1,
    // data: parsed_header?.groups,
  };
};

export const getApplicationHeaderBlock = (application_header) => {
  // let parsed_header = "";

  // const application_input_output = application_header?.match(
  //   application_header_input_output_identifier_regex
  // )?.groups?.input_output_id;

  // if (application_input_output === "I") {
  //   parsed_header = application_header?.match(
  //     application_header_input_block_regex
  //   );
  // } else if (application_input_output === "O") {
  //   parsed_header = application_header?.match(
  //     application_header_output_block_regex
  //   );
  // }

  return {
    application_header,
    block_identifier: 2,
    // data: parsed_header?.groups,
  };
};

export const getUserHeaderBlock = (user_header_string) => {
  let user_header = user_header_string;
  let textObj = {};
  while (user_header?.length > 4) {
    let match = user_header?.match(user_header_block_regex)?.groups;
    let replace_string = user_header?.match(user_header_block_regex)[0];
    if (replace_string) {
      textObj[match?.key] = match?.value;
    }
    user_header = user_header?.replace(replace_string, "");
  }

  return {
    user_header: user_header_string,
    block_identifier: 3,
    data: textObj,
  };
};

export const getTextBlock = (text_string) => {
  let text = text_string;
  let textObj = {};
  while (text?.length > 6) {
    let match = text?.match(text_block_regex)?.groups;
    let replace_string = text?.match(text_block_regex)[0];
    if (replace_string) {
      textObj[match?.key] = match?.value;
    }
    text = text?.replace(replace_string, "");
  }

  return {
    text: text_string,
    block_identifier: 4,
    data: textObj,
  };
};

//Commented code might be necessary if the response changes.

// export const getTrailerBlock = (trailer_string) => {
//   let trailer = trailer_string;
//   let textObj = {};
//   while (trailer?.length > 4) {
//     let match = trailer?.match(trailer_block_regex)?.groups;
//     let replace_string = trailer?.match(trailer_block_regex)[0];
//     if (replace_string) {
//       textObj[match?.key] = match?.value;
//     }
//     trailer = trailer?.replace(replace_string, "");
//   }
//   return {
//     trailer: trailer_string,
//     block_identifier: 5,
//     data: textObj,
//   };
// };
