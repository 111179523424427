import {
  FETCH_WALLET_TRANSACTION,
  SET_WALLET_TRANSACTION,
  FAILED_WALLET_TRANSACTION_FETCH,
} from '../actions/walletTransactionAction';

const initalState = {
  fetching: false,
  transactions: {},
  error: null
};

export const walletTansactionReducer = (
  state = initalState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_WALLET_TRANSACTION:
      return { fetching: true, transactions: {}, error: null };
    case SET_WALLET_TRANSACTION:
      return { ...state, fetching: false, transactions: payload, error: null };
    case FAILED_WALLET_TRANSACTION_FETCH:
      return { ...state, fetching: false, transactions: {}, error: payload };

    default:
      return state;
  }
};
