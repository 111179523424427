import axios from 'axios';
import { BASE_API_URL, accessToken } from 'src/config';
import { reduxErrorMessage } from 'src/utils/helper';

export const FETCH_CLIENT_USER = 'FETCH_CLIENT_USER';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const FAILED_CLIENT_FETCH = 'FAILED_CLIENT_FETCH';

export const fetchClientUser = () => ({
  type: FETCH_CLIENT_USER
});

export const setClientData = (payload) => ({
  type: SET_CLIENT_DATA,
  payload
});

export const setClientFetchError = (payload) => ({
  type: FAILED_CLIENT_FETCH,
  payload
});

export const getClientUsers = () => async (dispatch) => {
  dispatch(fetchClientUser());
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    const res = await axios.get(`${BASE_API_URL}/clientUser`, config);
    const { data, success } = res.data;
    if (success) {
      dispatch(setClientData(data));
    }
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setClientFetchError(message));
  }
};
