import {
  Box,
  Card,
  CircularProgress,
  Typography,
  Container,
  Button,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import OtpInput from "react-otp-input";
import { apiErrorMessage, toastNotification } from "src/utils/helper";
import { resendLoginOtp, sendOtpOnPhoneEmail } from "src/utils/NetworkUtils";
function LoginOTP({ style, sessionExpiryLogin }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpValue, setotpValue] = useState("");
  const [, setVerifyOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [otpSeconds, setOtpSeconds] = useState();
  const { t } = useTranslation();
  const { login, otp_login_user, dispatch } = useAuth();
  const [otpSentOnMobile, setOtpSentOnMobile] = useState(
    otp_login_user.is_otp_sent_on_mobile
  );
  const [otpSentOnEmail, setOtpSentOnEmail] = useState(
    otp_login_user.is_otp_sent_on_email
  );

  const otpRef = useRef();
  const timer = (a, b) => {
    let time = 30;

    var Stop = setInterval(() => {
      if (time >= 0) {
        time = time < 10 ? `0` + time : time;
        a(`00 : ${time}`);
      }

      if (time === "00") {
        clearInterval(Stop);
        b(false);
      }

      time -= 1;
    }, 1000);
  };
  // new functions
  useEffect(() => {
    setResendOtp(true);

    timer(setOtpSeconds, setResendOtp);
    return () => {
      otpRef.current = null;
    };
  }, []);

  const submitOTP = async (otpKey) => {
    if (otpKey.length < 6) {
      toastNotification("error", "Invalid OTP", 1000);
      return;
    }
    setVerifyOtp(true);
    setIsSubmitting(true);
    await login(otp_login_user.session_id, otp_login_user.auth_id, otpKey);

    setIsSubmitting(false);
    setotpValue("");
    otpRef.current && otpRef.current.focusInput(0);
  };
  const handleOtpInput = async (inputOtpValue) => {
    await setotpValue(inputOtpValue);

    if (inputOtpValue.length === 6) {
      await submitOTP(inputOtpValue);
    }
  };

  const resendMobileOtp = async () => {
    setResendOtp(true);
    try {
      const { success } = await resendLoginOtp(
        otp_login_user.auth_id,
        otp_login_user.session_id
      );
      if (success) {
        timer(setOtpSeconds, setResendOtp);
        toastNotification("success", "OTP sent successfully", 1500);
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 1500);
    }
  };
  const handleSendOtp = async () => {
    try {
      const requestObj = {
        auth_id: otp_login_user.auth_id,
        session_id: otp_login_user.session_id,
      };
      const { success, data } = await sendOtpOnPhoneEmail(requestObj);
      if (success) {
        setOtpSentOnEmail(data.is_otp_sent_on_email);
        setOtpSentOnMobile(data.is_otp_sent_on_mobile);
        toastNotification("success", "OTP sent", 1500);
      }
    } catch (error) {
      toastNotification("error", apiErrorMessage(error), 1500);
    }
  };
  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 3, px: 4, py: 5 }} style={style}>
        <Box textAlign="left">
          <Typography variant="h1" sx={{ mb: 3 }}>
            {t("Two-factor authentication")}
          </Typography>
          {otpSentOnEmail || otpSentOnMobile ? (
            <div>
              <Typography variant="h4" fontWeight={400} sx={{ mb: 1 }}>
                {t("A message with an OTP has been sent to your ")}
                {otp_login_user.phone_number ? (
                  <>
                    {t("phone ")}
                    <Typography
                      display="inline-block"
                      variant="h4"
                      fontWeight={900}
                    >
                      {"***" + otp_login_user.phone_number}
                    </Typography>
                    {t(" and ")}
                  </>
                ) : (
                  ""
                )}
                {otp_login_user.email ? (
                  <>
                    {t("email ")}
                    <Typography
                      display="inline-block"
                      variant="h4"
                      fontWeight={900}
                    >
                      {otp_login_user.email}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Typography>
              <Typography variant="h4" fontWeight={400} sx={{ mb: 1, mt: 4 }}>
                {t("Enter OTP to continue.")}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h4" fontWeight={500} sx={{ mb: 1 }}>
                {t("Please Enter the 6 digit OTP from the authenticator app")}
              </Typography>
            </div>
          )}
        </Box>
        <Box>
          <form>
            <Grid
              direction="column"
              justifyContent="center"
              alignItems="strech"
              py={3}
            >
              <OtpInput
                numInputs={6}
                ref={otpRef}
                isInputNum={true}
                value={otpValue}
                onChange={handleOtpInput}
                shouldAutoFocus
                containerStyle={{
                  justifyContent: "space-between",
                }}
                inputStyle={{
                  fontSize: "25px",
                  width: "50px",
                  border: "2px solid #242E6F",
                  height: "65px",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            {/* for 30 sec */}
            <Box textAlign="left" display={"flex"} alignItems={"center"}>
              {otpSentOnEmail || otpSentOnMobile ? (
                <Box textAlign="left" display={"flex"} alignItems={"center"}>
                  <Typography variant="h5" fontWeight={500}>
                    {t("Didn't receive an OTP?")}{" "}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    sx={{
                      ml: 1,
                      textDecoration: "underline",
                      color: `${resendOtp ? "#8c8c8c" : "#242E6F"}`,
                      cursor: resendOtp ? "not-allowed" : "pointer",
                    }}
                    onClick={resendOtp ? () => {} : resendMobileOtp}
                  >
                    {t("Resend")}{" "}
                  </Typography>
                  {resendOtp && (
                    <Typography
                      component={"p"}
                      sx={{ px: 1 }}
                      variant="h5"
                      fontWeight={"bold"}
                    >
                      {otpSeconds}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Typography
                  style={{
                    color: "#976DD0",
                  }}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  fontWeight={"bold"}
                  onClick={handleSendOtp}
                >
                  {t("Having trouble? Try another way")}
                </Typography>
              )}
            </Box>
            <Button
              size="large"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => submitOTP(otpValue)}
              style={{
                width: "100%",
                marginTop: "25px",
              }}
            >
              {isSubmitting ? (
                <CircularProgress
                  size="1rem"
                  color="secondary"
                  style={{ marginRight: "2px" }}
                />
              ) : null}{" "}
              Submit
            </Button>
            {!sessionExpiryLogin && (
              <Box mt={2} textAlign="center">
                <b
                  style={{
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    dispatch({
                      type: "OTP_LOGIN",
                      payload: {
                        otp_login_user: null,
                      },
                    });
                  }}
                >
                  Return to sign in
                </b>
              </Box>
            )}
          </form>
        </Box>
      </Card>
    </Container>
  );
}

export default LoginOTP;
