import { apiErrorMessage } from "src/utils/helper";
import { fetchAllPayouts } from "src/utils/NetworkUtils";

export const FETCH_PAYOUT = "FETCH_PAYOUT";
export const SET_PAYOUT = "SET_PAYOUT";
export const FAILED_PAYOUT_FETCH = "FAILED_PAYOUT_FETCH";

export const fetchPayout = () => ({
  type: FETCH_PAYOUT,
});

export const setPayout = (payload) => ({
  type: SET_PAYOUT,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_PAYOUT_FETCH,
  payload,
});

export const getAllPayouts = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchPayout());
  try {
    const res = await fetchAllPayouts(filterQueryArr, log);
    dispatch(setPayout(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};
