import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/store";

const AuthenticatedAndVerified = ({ children }) => {
  const { isAuthenticated, user, kyb_status } = useAuth();
  const { mode } = useSelector((state) => state.mode);
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (mode === "live") {
    if (
      !(user.is_mobile_verified && user.is_email_verified) ||
      kyb_status !== "APPROVED"
    ) {
      return <Redirect to="/preview/dashboards/analytics" />;
    }
  }

  return <>{children}</>;
};

AuthenticatedAndVerified.propTypes = {
  children: PropTypes.node,
};

export default AuthenticatedAndVerified;
