import * as Yup from "yup";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY, SITE_KEY_GOOGLE } from "src/utils/globalStaticData";
import { reCaptchaHostName } from "src/utils/helper";

function LoginJWT({ sessionExpiryLogin, user }) {
  const [showPassword, setShowPassword] = useState(false);
  const { otp_login } = useAuth();
  const isMountedRef = useRefMounted();
  const captcha_host_name_env = reCaptchaHostName();
  const { t } = useTranslation();
  const recaptchaRef = useRef();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Formik
      initialValues={{
        email: user?.email ? user?.email : "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Invalid email"))
          .max(255)
          .required(t("Email is required")),
        password: Yup.string()
          .max(255)
          .min(8)
          .required(t("Password is required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const recaptcha_token = await recaptchaRef.current.executeAsync();
          recaptchaRef.current.reset();
          await otp_login(values.email, values.password, recaptcha_token);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              disabled={!!user?.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              helperText={touched.email && errors.email}
              label={t("Email")}
              name="email"
              onBlur={handleBlur}
              placeholder="Email"
              onChange={handleChange}
              type="email"
              autoComplete="new-password"
              auto
              value={values.email}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margine="normal"
            helperText={touched.password && errors.password}
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Password"
            value={values.password}
            // id="outlined-adornment-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!sessionExpiryLogin && (
            <Box
              alignItems="center"
              display="flex"
              style={{
                marginTop: "1.5%",
                color: "#976DD0",
              }}
              justifyContent="flex-end"
            >
              <Link
                component={RouterLink}
                style={{
                  color: "#976DD0",
                }}
                to="/recover-password"
              >
                <b>{t("Forgot your password?")}</b>
              </Link>
            </Box>
          )}

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}
          <ReCAPTCHA
            style={{ visibility: sessionExpiryLogin ? "hidden" : "visible" }}
            ref={recaptchaRef}
            size="invisible"
            sitekey={captcha_host_name_env ? SITE_KEY_GOOGLE : SITE_KEY}
          />
          <Button
            sx={{ mt: 3, mb: 0.5 }}
            color="primary"
            startIcon={
              isSubmitting ? (
                <CircularProgress size="1rem" color="secondary" />
              ) : null
            }
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t("Log in")}
          </Button>
          {sessionExpiryLogin && (
            <Box mt={2}>
              <Typography textAlign={"center"} fontSize={14}>
                {user?.first_name &&
                  user?.last_name &&
                  `Not ${user?.first_name} ${user?.last_name}?`}{" "}
                <Link
                  style={{
                    textDecoration: "underline",
                  }}
                  href="/login"
                >
                  Click here
                </Link>{" "}
                to sign in with a different account.
              </Typography>
            </Box>
          )}
        </form>
      )}
    </Formik>
  );
}

export default LoginJWT;
