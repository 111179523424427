import {
  SET_CLIENT_DATA,
  FAILED_CLIENT_FETCH,
  FETCH_CLIENT_USER
} from '../actions/clientUserAction';

const initalState = {
  fetching: false,
  clientUsers: [],
  error: null
};

export const clientUserReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_CLIENT_USER:
      return { fetching: true, clientUsers: [], error: null };
    case SET_CLIENT_DATA:
      return { ...state, fetching: false, clientUsers: payload, error: null };
    case FAILED_CLIENT_FETCH:
      return { ...state, fetching: false, clientUsers: [], error: payload };

    default:
      return state;
  }
};
