import {
  FETCH_ORGANIZATION_ACCOUNTS,
  SET_ORGANIZATION_ACCOUNTS,
  FAILED_ORGANIZATION_ACCOUNTS_FETCH
} from '../actions/organizationAccountsAction';

const initalState = {
  fetching: false,
  organizationAccounts: {},
  error: null
};

export const organizationAccountsReducer = (
  state = initalState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_ORGANIZATION_ACCOUNTS:
      return { fetching: true, organizationAccounts: {}, error: null };
    case SET_ORGANIZATION_ACCOUNTS:
      return {
        ...state,
        fetching: false,
        organizationAccounts: payload,
        error: null
      };
    case FAILED_ORGANIZATION_ACCOUNTS_FETCH:
      return {
        ...state,
        fetching: false,
        organizationAccounts: {},
        error: payload
      };

    default:
      return state;
  }
};
