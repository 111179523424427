import { useContext } from "react";
import { Box, Hidden, IconButton } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { SidebarContext } from "src/contexts/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { SEARCHBOX_ROUTE_LIST } from "src/utils/globalStaticData";
import HeaderUserbox from "./Userbox";
import ApiMode from "./ApiMode";
import useAuth from "src/hooks/useAuth";
import RouteSearchBox from "./RouteSearchBox";

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height:${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left:${theme.sidebar.width};
            width: auto;
        }
`
);

function Header({ sx = {} }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { user, kyb_status, enabled_environment } = useAuth();
  const { is_developer, allowed_env } = user || {};

  const getReadOnlyRouteList = (route_list) => {
    return !user.is_admin && !user.is_developer
      ? route_list.filter((item) => item.rbac === "read_only")
      : route_list;
  };

  return (
    <HeaderWrapper display="flex" alignItems="center" sx={sx}>
      <Box display="flex" alignItems="center">
        {!is_developer &&
          allowed_env === "both" &&
          (!enabled_environment ||
            !(
              enabled_environment.includes("SANDBOX") ||
              enabled_environment.includes("PRODUCTION")
            )) && <ApiMode />}
      </Box>
      <Box display="flex" alignItems="center">
        <Hidden mdDown>
          <RouteSearchBox
            user={user}
            kyb_status={kyb_status}
            suggested_route_list={getReadOnlyRouteList(
              SEARCHBOX_ROUTE_LIST
            ).slice(1, 7)}
            all_route_list={getReadOnlyRouteList(SEARCHBOX_ROUTE_LIST)}
          />
        </Hidden>
        <HeaderUserbox />
        <Hidden lgUp>
          <IconButton color="primary" onClick={toggleSidebar} size="large">
            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
          </IconButton>
        </Hidden>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
