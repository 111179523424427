import {
  getApplicationHeaderBlock,
  getBasicHeaderBlock,
  getBlocks,
  getTextBlock,
  // getTrailerBlock,
  getUserHeaderBlock,
} from "./GetBlock";

export const getMT103Data = (swift) => {
  const {
    basic_header,
    application_header,
    user_header,
    text,
    // trailer,
  } = getBlocks(swift);
  const basic_header_data = getBasicHeaderBlock(basic_header);
  const application_header_data = getApplicationHeaderBlock(application_header);
  const user_header_data = getUserHeaderBlock(user_header);
  const text_data = getTextBlock(text);
  // const trailer_data = getTrailerBlock(trailer);

  return {
    basic_header: basic_header_data,
    application_header: application_header_data,
    user_header: user_header_data,
    text: text_data,
    // trailer: trailer_data,
  };
};

export default getMT103Data;
