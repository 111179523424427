import axios from "axios";
import { BASE_API_URL, accessToken } from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { apiErrorMessage } from "src/utils/helper";
import { fetchAllTransactions } from "src/utils/NetworkUtils";
export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const FAILED_TRANSACTION_FETCH = "FAILED_TRANSACTION_FETCH";
export const TRANSACTION_RESET = "TRANSACTION_RESET";

export const fetchTransaction = () => ({
  type: FETCH_TRANSACTION,
});

export const setTransaction = (payload) => ({
  type: SET_TRANSACTION,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_TRANSACTION_FETCH,
  payload,
});

export const getAllTransactionNew = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchTransaction());
  try {
    const res = await fetchAllTransactions(filterQueryArr, log);
    dispatch(setTransaction(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getAllSettled = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_API_URL}/torc/wallet-transaction?is_settled=1`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getAllPending = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_API_URL}/torc/wallet-transaction?is_settled=0`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getAllMatched = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_API_URL}/torc/wallet-transaction?is_matched=1`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getAllUnmatched = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(
      `${BASE_API_URL}/torc/wallet-transaction?is_matched=0`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
