import {
  FETCH_WEBHOOK,
  SET_WEBHOOK,
  FAILED_WEBHOOK_FETCH,
} from "../actions/webhookAction";

const initalState = {
  fetching: false,
  webhooks: {},
  success: false,
  error: null,
};

export const webhookReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_WEBHOOK:
      return { fetching: true, webhooks: {}, error: null };
    case SET_WEBHOOK:
      return { ...state, fetching: false, webhooks: payload, error: null };
    case FAILED_WEBHOOK_FETCH:
      return { ...state, fetching: false, webhooks: {}, error: payload };

    default:
      return state;
  }
};
