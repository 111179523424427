import {
  FETCH_PAYMENT,
  SET_PAYMENT,
  FAILED_PAYMENT_FETCH
} from '../actions/paymentAction';

const initalState = {
  fetching: false,
  payments: {},
  error: null
};

export const paymentReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_PAYMENT:
      return { fetching: true, payments: {}, error: null };
    case SET_PAYMENT:
      return { ...state, fetching: false, payments: payload, error: null };
    case FAILED_PAYMENT_FETCH:
      return { ...state, fetching: false, payments: {}, error: payload };

    default:
      return state;
  }
};
