import axios from "axios";
import { test_api_key } from "src/config";
import { apiErrorMessage, reduxErrorMessage } from "src/utils/helper";
import {
  fetchAllVirtualAccounts,
} from "src/utils/NetworkUtils";
export const FETCH_VIRTUAL_ACCOUNTS = "FETCH_VIRTUAL_ACCOUNTS";
export const SET_VIRTUAL_ACCOUNTS = "SET_VIRTUAL_ACCOUNTS";
export const FAILED_VIRTUAL_ACCOUNTS_FETCH = "FAILED_VIRTUAL_ACCOUNTS_FETCH";

export const NEW_VIRTUAL_ACCOUNTS_REQUEST = "NEW_VIRTUAL_ACCOUNTS_REQUEST";
export const NEW_VIRTUAL_ACCOUNTS_SUCCESS = "NEW_VIRTUAL_ACCOUNTS_SUCCESS";
export const NEW_VIRTUAL_ACCOUNTS_FAIL = "NEW_VIRTUAL_ACCOUNTS_FAIL";
export const VIRTUAL_ACCOUNTS_RESET = "VIRTUAL_ACCOUNTS_RESET";

export const fetchVirtualAccounts = () => ({
  type: FETCH_VIRTUAL_ACCOUNTS,
});

export const setVirtualAccount = (payload) => ({
  type: SET_VIRTUAL_ACCOUNTS,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_VIRTUAL_ACCOUNTS_FETCH,
  payload,
});

export const virtualAccountReset = () => async (dispatch) => {
  dispatch({ type: VIRTUAL_ACCOUNTS_RESET });
};

export const getAllVirtualAccounts = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchVirtualAccounts());
  try {
    const res = await fetchAllVirtualAccounts(filterQueryArr, log);
    dispatch(setVirtualAccount(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getVirtualAccountById = (id) => async (dispatch) => {
  dispatch(fetchVirtualAccounts());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };

    // const res = await axios.get(`${BASE_API_URL}/${id}`, config);
    const res = await axios.get(
      `https://api.pay-now.io/api/checkout/${id}`,
      config
    );
    const { data, success } = res.data;
    if (success) {
      dispatch(setVirtualAccount(data));
    }
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const newVirtualAccount = (
  amount,
  reference,
  currency,
  description,
  expiration,
  redirect_url
) => async (dispatch) => {
  try {
    dispatch({ type: NEW_VIRTUAL_ACCOUNTS_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };
    const { data } = await axios.post(
      "https://api.pay-now.io/api/checkout",
      { amount, reference, currency, description, expiration, redirect_url },
      config
    );
    dispatch({
      type: NEW_VIRTUAL_ACCOUNTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_VIRTUAL_ACCOUNTS_FAIL,
      payload: {
        message:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      },
    });
  }
};
