import useAuth from "src/hooks/useAuth";
import { Link } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { experimentalStyled } from "@mui/material/styles";
const SidebarDivider = experimentalStyled(Divider)(
  ({ theme }) => ` background: ${theme.sidebar.dividerBg}; `
);
function SidebarTopSection() {
  const { t } = useTranslation();
  const { user, kyb_status, organization } = useAuth();
  const { is_admin, is_partner_admin_login } = user || {};
  const { partner_permission_list = [] } = organization || {};
  const is_kyb_submission_allowed =
    partner_permission_list?.includes("is_kyb_submission_allowed") || false;

  const color = user.is_email_verified === true ? "#fff" : "#6c7480";
  return (
    <>
      {kyb_status === "NOT_STARTED" &&
        (is_partner_admin_login ? is_kyb_submission_allowed : is_admin) && (
          <>
            <Link
              to={!user.is_email_verified ? "#" : "/account/onboarding"}
              style={{
                textDecoration: "none",
                "&:hover": { textDecoration: "none" },
              }}
            >
              <Button
                variant="contained"
                fullWidth
                color="primary"
                style={{
                  color: `${color}`,
                  marginTop: "20px",
                }}
                sx={{
                  mb: 3,
                  ":disabled": {
                    background: "#223354",
                  },
                }}
                disabled={!user.is_email_verified}
              >
                {t("Activate your account")}
              </Button>
            </Link>
            <SidebarDivider sx={{ mx: 1 }} />
          </>
        )}
    </>
  );
}
export default SidebarTopSection;
