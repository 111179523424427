export const UPDATE_API_MODE = "UPDATE_API_MODE";
export const DEVELOPER_UPDATE_API_MODE = "DEVELOPER_UPDATE_API_MODE";

export const updateApiMode = (mode) => ({
  type: "UPDATE_API_MODE",
  payload: {
    mode,
  },
});

export const developerUpdateApiMode = (mode) => ({
  type: "DEVELOPER_UPDATE_API_MODE",
  payload: {
    mode,
  },
});
