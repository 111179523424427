import React from "react";
import useAuth from "./hooks/useAuth";
import routes, { renderRoutes } from "./router";
function RouterComp() {
  const { user, isAuthenticated } = useAuth();
  const { is_admin, is_developer } = user || {};
  return <>{renderRoutes(routes, is_admin, isAuthenticated, is_developer)}</>;
}

export default RouterComp;
