import PropTypes from "prop-types";
import { Modal, Box, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "90vw",
  height: "auto",
  bgcolor: "#fff",
  borderRadius: "8px",
  border: "0px solid #000",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  p: 4,
};

const ModalBox = ({
  open,
  handleClose,
  modalAriaLabelTitle,
  modalAriaDescription,
  style,
  children,
  cancellable = true,
  backgroundStyle,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={modalAriaLabelTitle}
      aria-describedby={modalAriaDescription}
      disableAutoFocus={true}
      BackdropProps={backgroundStyle}
    >
      <Box sx={{ ...modal_style, ...style }}>
        {cancellable && (
          <CancelIcon
            sx={{
              position: "absolute",
              cursor: "pointer",
              right: 10,
              top: 10,
            }}
            onClick={() => handleClose()}
          />
        )}
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};

ModalBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  modalAriaLabelTitle: PropTypes.string,
  modalAriaDescription: PropTypes.string,
  style: PropTypes.object,
};

ModalBox.defaultProps = {
  open: false,
  handleClose: () => {},
  modalAriaLabelTitle: "modal-title",
  modalAriaDescription: "modal-description",
  style: {},
};

export default ModalBox;
