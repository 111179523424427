import {
  FETCH_VIRTUAL_ACCOUNTS,
  SET_VIRTUAL_ACCOUNTS,
  FAILED_VIRTUAL_ACCOUNTS_FETCH,
  NEW_VIRTUAL_ACCOUNTS_REQUEST,
  NEW_VIRTUAL_ACCOUNTS_SUCCESS,
  NEW_VIRTUAL_ACCOUNTS_FAIL,
  VIRTUAL_ACCOUNTS_RESET
} from '../actions/virtualAccountActions';

const initalState = {
  fetching: false,
  allVirtualAccounts: {},
  success: false,
  error: null
};

export const virtualAccountsReducer = (
  state = initalState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_VIRTUAL_ACCOUNTS:
      return { fetching: true, allVirtualAccounts: {}, error: null };
    case SET_VIRTUAL_ACCOUNTS:
      return {
        ...state,
        fetching: false,
        allVirtualAccounts: payload,
        error: null
      };
    case FAILED_VIRTUAL_ACCOUNTS_FETCH:
      return {
        ...state,
        fetching: false,
        allVirtualAccounts: {},
        error: payload
      };

    default:
      return state;
  }
};

export const newVirtualAccountsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NEW_VIRTUAL_ACCOUNTS_REQUEST:
      return { fetching: true };
    case NEW_VIRTUAL_ACCOUNTS_SUCCESS:
      return { fetching: false, allVirtualAccounts: payload, success: true };
    case NEW_VIRTUAL_ACCOUNTS_FAIL:
      return { fetching: false, error: payload };
    case VIRTUAL_ACCOUNTS_RESET:
      return {};

    default:
      return state;
  }
};
