import React, { useState } from 'react';
import {
  ThemeProvider as TProvider,
  StyledEngineProvider,
} from '@mui/material';
import { themeCreator } from './base';
import StylesProvider from '@mui/styles/StylesProvider';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import rtlPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'bloom-ui'
//   // @ts-ignore
//   stylisPlugins: [rtlPlugin]
// });

export const ThemeContext = React.createContext((themeName) => {});

const ThemeProvider = function (props) {

  // || 'PureLightTheme'
  const curThemeName = 'LightBloomTheme';
  //   (mode === 'live'
  //     ? 'LightBloomTheme'
  //     : mode === 'test'
  //     ? 'LightBloomTheme'
  //     : null) || localStorage.getItem('appTheme');
  // localStorage.setItem('appTheme', curThemeName);

  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <StyledEngineProvider injectFirst>
          <TProvider theme={theme}>{props.children}</TProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProvider;
