import axios from "axios";
import { test_api_key } from "src/config";
import { apiErrorMessage, reduxErrorMessage } from "src/utils/helper";
import { fetchAllWallets, fetchCustomerWallets } from "src/utils/NetworkUtils";
export const FETCH_WALLET = "FETCH_WALLET";
export const SET_WALLET = "SET_WALLET";
export const FAILED_WALLET_FETCH = "FAILED_WALLET_FETCH";

export const fetchWallet = () => ({
  type: FETCH_WALLET,
});

export const setWallet = (payload) => ({
  type: SET_WALLET,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_WALLET_FETCH,
  payload,
});

export const getAllWallet = (limit, page) => async (dispatch, getState) => {
  const { mode } = getState().mode;
  dispatch(fetchWallet());
  try {
    const filter_arr = [
      {
        filter_key: "limit",
        filter_value: limit,
      },
      {
        filter_key: "page",
        filter_value: page,
      },
    ];
    const res = await fetchAllWallets(filter_arr, mode);
    dispatch(setWallet(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getAllCustomerWallets =
  ({ filterQueryArr = [], log = false } = {}) =>
  async (dispatch) => {
    dispatch(fetchWallet());
    try {
      const res = await fetchCustomerWallets(filterQueryArr, log);
      dispatch(setWallet(res));
    } catch (error) {
      const message = apiErrorMessage(error);
      dispatch(setError(message));
    }
  };

export const getWalletById = (id) => async (dispatch) => {
  dispatch(fetchWallet());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };

    // const res = await axios.get(`${BASE_API_URL}/${id}`, config);
    const res = await axios.get(
      `https://api.pay-now.io/api/wallet/${id}`,
      config
    );
    const data = res.data;

    dispatch(setWallet(data));
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
