import {
  FETCH_CUSTOMER,
  SET_CUSTOMER,
  FAILED_CUSTOMER_FETCH
} from '../actions/customerAction';

const initalState = {
  fetching: false,
  customers: {},
  error: null
};

export const customerReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMER:
      return { fetching: true, customers: {}, error: null };
    case SET_CUSTOMER:
      return { ...state, fetching: false, customers: payload, error: null };
    case FAILED_CUSTOMER_FETCH:
      return { ...state, fetching: false, customers: {}, error: payload };

    default:
      return state;
  }
};
