import {
  Box,
  Divider,
  FormControl,
  Switch,
  Typography,
} from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "src/store";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updateApiMode } from "src/store/actions/apiModeAction";
import { useHistory } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const ThemeToggleWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2)};
`
);
const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const ToggleSwitch = experimentalStyled(Switch)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  margin: "0 10px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "rgb(128 128 128 / 34%)" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 5,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>')`,
      right: 5,
    },
  },
}));

const ApiMode = (_props) => {
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.mode);
  const { initialise } = useAuth();
  const history = useHistory();
  const handleChange = async (event) => {
    dispatch(updateApiMode(event.target.value));
    history.push("/preview/dashboards/analytics");
    await initialise(false, event.target.value);
  };
  const classes = useStyles();

  return (
    <>
      <ThemeToggleWrapper>
        <FormControl fullWidth variant="outlined">
          <FormControlLabel
            control={
              <ToggleSwitch
                checked={mode === "test"}
                onChange={handleChange}
                name="checked"
                value={mode === "live" ? "test" : "live"}
              />
            }
            label={
              <Typography
                style={{
                  color: `${mode === "test" ? "#fff" : "#00000061"}`,
                  background: `${mode === "test" ? "#242e6f" : "#80808057"}`,
                  fontSize:"13px",
                  padding: "4.5px 9px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                {"Sandbox"}
              </Typography>
            }
            classes={{
              label: classes.label,
            }}
          />
        </FormControl>
      </ThemeToggleWrapper>
      <Divider />
    </>
  );
};

export default ApiMode;
