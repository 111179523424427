import { useContext } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SidebarContext } from "src/contexts/SidebarContext";
import SidebarTopSection from "./SidebarTopSection";

import { Box, Drawer, Hidden, Typography } from "@mui/material";

import { experimentalStyled } from "@mui/material/styles";
import SidebarMenu from "./SidebarMenu";
import useAuth from "src/hooks/useAuth";

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2)};
`
);

function Sidebar({ sx = {} }) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { organization } = useAuth();
  const closeSidebar = () => toggleSidebar();

  const { partner_logo } = organization;

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper sx={sx}>
          <Scrollbars autoHide>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"100%"}
              justifyContent={"space-between"}
            >
              <Box>
                <TopSection>
                  <Link to="/preview/dashboards/analytics">
                    <img
                      style={{ width: "85%", height: "auto" }}
                      src={
                        partner_logo ||
                        "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/Finmo_logo_color%2Bwhite.png"
                      }
                      alt="logo"
                    />
                  </Link>

                  <SidebarTopSection />
                </TopSection>

                <SidebarMenu />
              </Box>
              {partner_logo && (
                <Box
                  display={"flex"}
                  justifyContent="flex-end"
                  sx={{ mt: 1, mr: 1 }}
                >
                  <Typography
                    variant="subtitle"
                    textAlign={"right"}
                    fontSize={"12px"}
                  >
                    {" "}
                    Powered by{" "}
                    <img
                      style={{ width: "20%", marginLeft: "7px", opacity: 0.7 }}
                      src={
                        "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/Finmo_logo_color%2Bwhite.png"
                      }
                      alt="logo"
                    />{" "}
                  </Typography>
                </Box>
              )}
            </Box>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <Box
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
                justifyContent={"space-between"}
              >
                <Box>
                  <TopSection>
                    <Link to="/preview/dashboards/analytics">
                      <img
                        style={{ width: "85%", height: "auto" }}
                        src={
                          partner_logo ||
                          "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/Finmo_logo_color%2Bwhite.png"
                        }
                        alt="logo"
                      />
                    </Link>

                    <SidebarTopSection />
                  </TopSection>
                  <SidebarMenu />
                </Box>

                {partner_logo && (
                  <Box
                    display={"flex"}
                    justifyContent="flex-end"
                    sx={{ mt: 1, mr: 1 }}
                  >
                    <Typography variant="subtitle" textAlign={"right"}>
                      {" "}
                      Powered by{" "}
                      <img
                        style={{ width: "25%", marginLeft: "7px" }}
                        src={
                          "https://finmo-assets.s3.ap-southeast-2.amazonaws.com/Finmo_logo_color%2Bwhite.png"
                        }
                        alt="logo"
                      />{" "}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
