import {
  FETCH_REFUND,
  SET_REFUND,
  FAILED_REFUND_FETCH,
  NEW_REFUND_REQUEST,
  NEW_REFUND_SUCCESS,
  NEW_REFUND_FAIL
} from '../actions/refundAction';

const initalState = {
  fetching: false,
  refunds: {},
  success: false,
  error: null
};

export const refundReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_REFUND:
      return { fetching: true, refunds: {}, error: null };
    case SET_REFUND:
      return { ...state, fetching: false, refunds: payload, error: null };
    case FAILED_REFUND_FETCH:
      return { ...state, fetching: false, refunds: {}, error: payload };

    default:
      return state;
  }
};

export const newRefundReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NEW_REFUND_REQUEST:
      return { fetching: true };
    case NEW_REFUND_SUCCESS:
      return { fetching: false, refunds: payload, success: true };
    case NEW_REFUND_FAIL:
      return { fetching: false, error: payload };

    default:
      return state;
  }
};
