import {
  FETCH_WALLET,
  SET_WALLET,
  FAILED_WALLET_FETCH
} from '../actions/walletAction';

const initalState = {
  fetching: false,
  wallets: {},
  error: null
};

export const walletReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_WALLET:
      return { fetching: true, wallets: {}, error: null };
    case SET_WALLET:
      return { ...state, fetching: false, wallets: payload, error: null };
    case FAILED_WALLET_FETCH:
      return { ...state, fetching: false, wallets: {}, error: payload };

    default:
      return state;
  }
};
