import axios from 'axios';
import { BASE_API_URL, test_api_key } from 'src/config';
const accessToken = window.localStorage.getItem('accessToken') || '';

export const errorMessage = (error) => ({
  message:
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message
});

export const getPayment = async (paymentId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };
    const response = await axios.get(
      // `${BASE_API_URL}/payment?id=${paymentId}`,
      `https://api.pay-now.io/api/payment/${paymentId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getWallet = async (walletId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };
    const response = await axios.get(
      // `${BASE_API_URL}/wallet?id=${walletId}`,
      `https://api.pay-now.io/api/wallet/${walletId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getCheckout = async (checkoutId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };
    const response = await axios.get(
      // `${BASE_API_URL}/checkout?id=${checkoutId}`,
      `https://api.pay-now.io/api/checkout/${checkoutId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const createKYB = async (data, account_id) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.post(
      `${BASE_API_URL}/kyb`,
      { account_id, ...data },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const getKYB = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.get(`${BASE_API_URL}/kyb`, config);
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
export const updateKYB = async (query, kyb_id) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(
      `${BASE_API_URL}/kyb`,
      {
        query: {
          kyb_id,
          business_name: query.business_name,
          legal_business_name: query.legal_business_name,
          tax_registration_number: query.tax_registration_number,
          b_address_line1: query.b_address_line1,
          b_address_line2: query.b_address_line2,
          b_city: query.b_city,
          b_state: query.b_state,
          b_zip_code: query.b_zip_code,
          b_country: query.b_country,
          bank_name: query.bank_name,
          bank_account_holder_name: query.bank_account_holder_name,
          bank_account_number: query.bank_account_number,
          bsb_number: query.bsb_number
        }
      },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateWebHookUrl = async (env, webhook_url) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(
      `${BASE_API_URL}/account/webhookUrl`,
      {
        env: env === 'live' ? 'prod' : env,
        webhook_url
      },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const createApiKey = async (env) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.post(
      `${BASE_API_URL}/account/apiKey`,
      {
        env: env === 'live' ? 'prod' : env
      },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const singleClientUser = async (clientId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.get(
      `${BASE_API_URL}/clientUser?client_user_id=${clientId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getBankAccountDetails = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.get(
      `${BASE_API_URL}/kyb/account-details`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const resendVerifyEmail = async () => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.post(
      `${BASE_API_URL}/account/email/sendVerificationEmail`,
      {},
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const verifyMobileOtp = async (otp) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.post(
      `${BASE_API_URL}/account/mobile/verify`,
      { otp },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

// fetching account summary

export const getAccountSummary = () => {
  const key = window.localStorage.getItem('test_api_key');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${key}`
    }
  };

  axios
    .get('https://api.pay-now.io/api/overview/account-summary', config)
    .then((res) => {
      return res.data.data;
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });

  // try {
  //   const config = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${test_api_key}`
  //     }
  //   };

  //   const response = await axios.get(
  //     'https://api.pay-now.io/api/account/summary',
  //     config
  //   );
  //   const data = response.data.data;
  //   console.log(data);
  //   return data;
  // } catch (error) {
  //   console.log(error);
  // }
};

export const updateIpListing = async (iplist) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(
      `${BASE_API_URL}/account`,
      iplist,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateLogo = async (url) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(`${BASE_API_URL}/account`, url, config);
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updatePaymentInst = async (paymentinst) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(
      `${BASE_API_URL}/account`,
      paymentinst,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const uploadOrgDocs = async (file) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.post(
      `${BASE_API_URL}/kybDocs/fetchUploadPath`,
      {
        filename: file.name,
        filetype: file.type
      },
      config
    );
    const res = response.data;
    let filetype = file.type;
    // var formdata = new FormData();
    // formdata.append("", file, file.name);
    var requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': filetype
      },
      body: file,
      redirect: 'follow'
    };

    fetch(res.data.url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log('done');
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateBankDetails = async (query, kyb_id) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    const response = await axios.patch(
      `${BASE_API_URL}/kyb`,
      {
        query: {
          kyb_id,
          bank_name: query.bank_name,
          bank_account_holder_name: query.bank_account_holder_name,
          bank_account_number: query.bank_account_number
        }
      },
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
