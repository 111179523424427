import axios from "axios";
import {
  test_api_key,
} from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { reduxErrorMessage } from "src/utils/helper";
import { fetchAllRefund } from "src/utils/NetworkUtils";
export const FETCH_REFUND = "FETCH_REFUND";
export const SET_REFUND = "SET_REFUND";
export const FAILED_REFUND_FETCH = "FAILED_REFUND_FETCH";

export const NEW_REFUND_REQUEST = "NEW_REFUND_REQUEST";
export const NEW_REFUND_SUCCESS = "NEW_REFUND_SUCCESS";
export const NEW_REFUND_FAIL = "NEW_REFUND_FAIL";

// const accessToken = 'test-ac6790bd-edee-42c4-9a58-d80f99baf35f';

export const fetchRefund = () => ({
  type: FETCH_REFUND,
});

export const setRefund = (payload) => ({
  type: SET_REFUND,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_REFUND_FETCH,
  payload,
});

export const getAllRefund = (mode, limit, page) => async (dispatch) => {
  dispatch(fetchRefund());
  try {
    const res = await fetchAllRefund(mode, limit, page);
    dispatch(setRefund(res));
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getRefund = async (refundId) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };
    const response = await axios.get(
      `https://api.pay-now.io/api/refund/${refundId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const newRefund = (payment_id, type, amount) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REFUND_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };
    const { data } = await axios.post(
      // `${BASE_URL}/refund`,
      `https://api.pay-now.io/api/refund`,
      { payment_id, type, amount },
      config
    );
    dispatch({
      type: NEW_REFUND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_REFUND_FAIL,
      payload: {
        message:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      },
    });
  }
};
