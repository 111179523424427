import PropTypes from "prop-types";
import { experimentalStyled } from "@mui/material/styles";
import { Box, Alert, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector, useDispatch } from "src/store";
import useAuth from "src/hooks/useAuth";
import { useState, useEffect } from "react";
import { isEmptyObj, isInvestDashboard } from "src/utils/helper";
import { useQuery } from "src/hooks/querys";
import { updateApiMode } from "src/store/actions/apiModeAction";

const MainWrapper = experimentalStyled(Box)(
  ({ theme, isSideBarHeaderVisible }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        // @media (min-width: ${theme.breakpoints.values.lg}px) {
        //   padding-left:${theme.sidebar.width};
        // }
        ${
          !isSideBarHeaderVisible
            ? ""
            : `@media (min-width: ${theme.breakpoints.values.lg}px) {
                padding-left:${theme.sidebar.width};
              }`
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme, isSideBarHeaderVisible }) => `
        width: 100%;
        // margin-top:${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        ${!isSideBarHeaderVisible ? "" : `margin-top:${theme.header.height};`}
`
);

const AlertBox = experimentalStyled(Alert)(
  ({ theme }) => `
        margin-top: 2px;
        border-radius: 0px;
        text-align: center;
        justify-content: center;
        overflow: auto;
        color: #fff;
        & .MuiAlert-message {
          padding: 0;
          font-size: 12px;
        }
`
);

const AccentSidebarLayout = ({ children }) => {
  const { mode } = useSelector((state) => state.mode);
  const { authData, user, initialise, organization } = useAuth();
  const location = useLocation();
  const [bo_login_state, setBoLoginState] = useState(false);
  const query = useQuery();
  const env = query.get("mode");
  const dispatch = useDispatch();
  const { org_id, partner_id } = organization;
  const isSideBarHeaderVisible = isInvestDashboard();
  const theme = useTheme();

  useEffect(() => {
    if (isEmptyObj(authData || {})) {
      setBoLoginState(
        localStorage.getItem("is_bo_admin_login") ||
          localStorage.getItem("is_partner_admin_login")
      );
    } else {
      const { is_bo_admin_login, is_partner_admin_login } = authData || {};
      setBoLoginState(is_bo_admin_login || is_partner_admin_login);
    }
  }, [authData]);

  useEffect(() => {
    if (!user.is_email_verified) {
      initialise(true);
    }
    if (env === "sandbox") {
      dispatch(updateApiMode("test"));
    }
    if (env === "production") {
      dispatch(updateApiMode("live"));
    }
  }, [location]);

  return (
    <>
      {bo_login_state && (
        <AlertBox
          sx={{ position: "fixed", width: "100vw", m: "auto" }}
          variant="filled"
          severity="warning"
          icon={false}
        >
          This is a user-impersonated session. Please be careful.
        </AlertBox>
      )}

      {isSideBarHeaderVisible && (
        <>
          <Sidebar sx={bo_login_state && { mt: "30px", pb: 4 }} />
          <Header sx={bo_login_state && { mt: "30px" }} />
        </>
      )}

      <MainWrapper isSideBarHeaderVisible={isSideBarHeaderVisible}>
        <MainContent
          sx={bo_login_state && { pt: 4 }}
          isSideBarHeaderVisible={isSideBarHeaderVisible}
        >
          {mode === "test" && (
            <>
              <div style={{ borderTop: "5px solid #FFA319" }}></div>
              <AlertBox
                variant="filled"
                severity="warning"
                sx={{
                  width: "fit-content",
                  margin: "auto",
                  padding: "2px 20px",
                  borderRadius: "0 0 7px 7px",
                }}
                icon={false}
              >
                Sandbox Data
              </AlertBox>
            </>
          )}
          {children}
        </MainContent>
      </MainWrapper>
    </>
  );
};

AccentSidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default AccentSidebarLayout;
