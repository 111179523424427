import {
    FETCH_TRANSACTION,
    SET_TRANSACTION,
    FAILED_TRANSACTION_FETCH
  } from '../actions/transactionAction';
  
  const initalState = {
    fetching: false,
    transactions: {},
    error: null
  };
  
  export const transactionReducer = (
    state = initalState,
    { type, payload }
  ) => {
    switch (type) {
      case FETCH_TRANSACTION:
        return { fetching: true, transactions: {}, error: null };
      case SET_TRANSACTION:
        return { ...state, fetching: false, transactions: payload, error: null };
      case FAILED_TRANSACTION_FETCH:
        return { ...state, fetching: false, transactions: {}, error: payload };
  
      default:
        return state;
    }
  };
  