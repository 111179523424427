export const basic_header_regex = /{1:(?<basic_header>([^}^{])+)}/;
//Matches string starting with '{1:' , ending in '}' and containing one or more characters in between (any character except '{' or '}' )
export const application_header_regex = /{2:(?<application_header>([^}^{])+)}/;
//Matches string starting with '{2:' , ending in '}' and containing one or more characters in between (any character except '{' or '}' )
export const user_header_regex = /{3:(?<user_header>({.[^}^{]+})+)}/;
//Matches string starting with '{3:' , ending in '}' and containing one or more objects in between (format : { any character except '{' or '} })
export const text_regex = /(?<text>{4:([^}^{])+)}/;
//Matches string starting with '{4:' , ending in '}' and containing one or more characters in between (any character except '{' or '}' )
export const trailer_regex = /(?<trailer>{5:({.[^}^{]+})+})/;
//Matches string starting with '{5:' , ending in '}' and containing one or more objects in between (format : { any character except '{' or '} })

export const basic_header_block_regex = /(?<application_id>[F|A|L])(?<service_id>[01|21]{2})(?<lt_address>.{12})(?<session_number>\d{4})(?<sequence_number>\d{6}).*/;
//Application id=F/A/L, Service id=01/21, lt_address=12 character, session number=4 numeric characters, sequence number=6 numeric characters
export const application_header_input_output_identifier_regex = /(?<input_output_id>[I/O]).*/;
//Matched input/output id of application_header (I/O)
export const application_header_input_block_regex = /(?<input_output_id>[I/O])(?<swift_message_type>\d{3})(?<destination_address>.{12})(?<priority>[S|U|N])?(?<delivery_monitoring>[1|2|3])?(?<obsolescence_period>\d{3})?.*/;
export const application_header_output_block_regex = /(?<input_output_id>[I/O])(?<swift_message_type>\d{3})(?<input_time>\d{4})(?<message_input_reference>.{28})(?<output_date>\d{6})(?<output_time>[0-9]{4})(?<priority>[S|U|N])?.*/;
export const user_header_block_regex = /{(?<key>[\d]{3}):(?<value>[^:^}]+)}/;
//Extracts user header objects in key value-notation
export const text_block_regex = /:(?<key>[0-9A-Z]{2,3}):(?<value>[^:^}]*)/;
//Extracts text header fields in key value-notation
export const trailer_block_regex = /{(?<key>[A-Z]{3}):(?<value>[^:^}]+)}/;
//Extracts trailer objects in key-value notation
