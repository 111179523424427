import axios from "axios";
import { apiErrorMessage } from "src/utils/helper";
import { fetchAllWebhookNotification } from "src/utils/NetworkUtils";
export const FETCH_WEBHOOK = "FETCH_WEBHOOK";
export const SET_WEBHOOK = "SET_WEBHOOK";
export const FAILED_WEBHOOK_FETCH = "FAILED_WEBHOOK_FETCH";

export const fetchWebhook = () => ({
  type: FETCH_WEBHOOK,
});

export const setWebhook = (payload) => ({
  type: SET_WEBHOOK,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_WEBHOOK_FETCH,
  payload,
});

export const getAllWebhook = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchWebhook());
  try {
    const res = await fetchAllWebhookNotification(filterQueryArr, log);
    dispatch(setWebhook(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getWebhook = async (webhookId) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `https://api.pay-now.io/api/webhook-notification/${webhookId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return apiErrorMessage(error);
  }
};
