import axios from "axios";
import {
  test_api_key,
} from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { apiErrorMessage } from "src/utils/helper";
import { fetchAllWalletTransactions } from "src/utils/NetworkUtils";
export const FETCH_WALLET_TRANSACTION = "FETCH_WALLET_TRANSACTION";
export const SET_WALLET_TRANSACTION = "SET_WALLET_TRANSACTION";
export const FAILED_WALLET_TRANSACTION_FETCH =
  "FAILED_WALLET_TRANSACTION_FETCH";

export const fetchWalletTransaction = () => ({
  type: FETCH_WALLET_TRANSACTION,
});

export const setWalletTransaction = (payload) => ({
  type: SET_WALLET_TRANSACTION,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_WALLET_TRANSACTION_FETCH,
  payload,
});

export const getAllWalletTransaction = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchWalletTransaction());
  try {
    const res = await fetchAllWalletTransactions(filterQueryArr, log);
    dispatch(setWalletTransaction(res));
  } catch (error) {
    const message = apiErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getWalletPayment = async (walletPaymentId) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${test_api_key}`,
      },
    };
    const response = await axios.get(
      `https://api.pay-now.io/api/wallet-payment/${walletPaymentId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
