const supportWidget = {
  show: () => {
    window.FreshworksWidget("show");
  },

  hide: () => {
    window.FreshworksWidget("hide");
  },

  prefillCustomer: (data = {}) => {
    window.FreshworksWidget("identify", "ticketForm", data);
  },

  prefillForm: (data = {}) => {
    window.FreshworksWidget("prefill", "ticketForm", data);
  },

  disableFormFields: (fields = []) => {
    window.FreshworksWidget("disable", "ticketForm", fields);
  },

  hideFormFields: (fields = []) => {
    window.FreshworksWidget("hide", "ticketForm", fields);
  },
};

export default supportWidget;
