import { reduxErrorMessage } from "src/utils/helper";
import { getAllOrganizationAccounts } from "src/utils/NetworkUtils";
export const FETCH_ORGANIZATION_ACCOUNTS = "FETCH_ORGANIZATION_ACCOUNTS";
export const SET_ORGANIZATION_ACCOUNTS = "SET_ORGANIZATION_ACCOUNTS";
export const FAILED_ORGANIZATION_ACCOUNTS_FETCH =
  "FAILED_ORGANIZATION_ACCOUNTS_FETCH";

export const fetchOrganizationAccounts = () => ({
  type: FETCH_ORGANIZATION_ACCOUNTS,
});

export const setOrganizationAccounts = (payload) => ({
  type: SET_ORGANIZATION_ACCOUNTS,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_ORGANIZATION_ACCOUNTS_FETCH,
  payload,
});

export const getAllOrgAccounts = ({
  filterQueryArr = [],
  log = false,
} = {}) => async (dispatch) => {
  dispatch(fetchOrganizationAccounts());
  try {
    const response = await getAllOrganizationAccounts(filterQueryArr, log);
    dispatch(setOrganizationAccounts(response));
    return response;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
